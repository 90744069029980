import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import DateRangePickerCustom from "../../../app/components/DateRangePickerCustom";
import api from "../../../api";
import { numberFormat } from "../../../functions";

import { fade, makeStyles } from "@material-ui/core/styles";
import { Popover, Button, Typography } from "@material-ui/core";


export default function SalesDashboardTotalSales(props) {
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [salesReport, setSalesReport] = useState({});

  const useStyles1 = makeStyles( theme => ( {
    popover: {
        pointerEvents: "none"    
      },
    paper: {
        padding: theme.spacing( 2 ),
        background: "#E8FCFD"
      }
    } )
  );

  const classes1 = useStyles1();
  const [anchorSaCo, setAnchorSaCo] = React.useState( null );
  const openSaCo = Boolean( anchorSaCo );

  function handlePopoverOpenSaCo( event ) {
    setAnchorSaCo( event.currentTarget );
  }
  function handlePopoverCloseSaCo() {
    setAnchorSaCo( null );
  }

  //const classes2 = useStyles1();
  const [anchorDiAm, setAnchorDiAm] = React.useState( null );
  const openDiAm = Boolean( anchorDiAm );

  function handlePopoverOpenDiAm( event ) {
    setAnchorDiAm( event.currentTarget );
  }
  function handlePopoverCloseDiAm() {
    setAnchorDiAm( null );
  }

  const [anchorSaAm, setAnchorSaAm] = React.useState( null );
  const openSaAm = Boolean( anchorSaAm );

  function handlePopoverOpenSaAm( event ) {
    setAnchorSaAm( event.currentTarget );
  }
  function handlePopoverCloseSaAm() {
    setAnchorSaAm( null );
  }

  const [anchorSaTo, setAnchorSaTo] = React.useState( null );
  const openSaTo = Boolean( anchorSaTo );

  function handlePopoverOpenSaTo( event ) {
    setAnchorSaTo( event.currentTarget );
  }
  function handlePopoverCloseSaTo() {
    setAnchorSaTo( null );
  }

  const [anchorDoPa, setAnchorDoPa] = React.useState( null );
  const openDoPa = Boolean( anchorDoPa );

  function handlePopoverOpenDoPa( event ) {
    setAnchorDoPa( event.currentTarget );
  }
  function handlePopoverCloseDoPa() {
    setAnchorDoPa( null );
  }



  const fetchData = (start, end) => {
    let payload = {
      StartDate: moment(start).format("yyyy/MM/DD"),
      EndDate: moment(end).format("yyyy/MM/DD"),
    }

    return api
      .get(`dashboard/getTotal`, {
        params: payload,
      })
      .then(
        (result) => {
          if (result.status === 201) {
            setSalesReport(result.data.body);
          }
        },
        (error) => {
          setSalesReport({});
          console.log(error);
        }
      );
  }

  const handleCallback = async (start, end) => {
    await setStartDate(start);
    await setEndDate(end);
    await fetchData(start, end);
  }

  useEffect( () => { fetchData( startDate, endDate ); }, [] );

  return (
    <>
      <div className="card">
        <div className="card-header border-0 pt-5 bg-light">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  VENTAS TOTALES
                </span>
              </h3>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
                Periodo de consulta:
              <DateRangePickerCustom
                handleCallback={handleCallback}
                size="large"
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-8 col-lg-8 col-xxl-8 mb-5 bg-dark rounded-xl py-5">
              <div className="row m-0">
                <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7"
                      aria-owns="mouse-over-salesamount" 
                      aria-haspopup="true"
                      onMouseEnter={ handlePopoverOpenSaCo } 
                      onMouseLeave={ handlePopoverCloseSaCo } >
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>

                  <span className="font-weight-bolder font-size-h3">
                    <i className="flaticon-coins text-warning display-4 text-white font-weight-bold"></i>{"   "}
                    {numberFormat(salesReport.SalesContract ?? 0)}
                  </span>
                  <span className="text-warning font-weight-bold font-size-h6 mt-2">
                    <br></br>Monto Total de Ventas
                  </span>

                </div>
                <Popover id="mouse-over-salesamount" 
                        className={ classes1.popover } 
                        classes={ { paper: classes1.paper } }
                        open={ openSaCo } 
                        anchorEl={ anchorSaCo } 
                        anchorOrigin={ { vertical: "top", horizontal: "center" } }
                        transformOrigin={ { vertical: "center", horizontal: "left" } } 
                        onClose={ handlePopoverCloseSaCo } 
                        disableRestoreFocus >
                          <strong className="font-weight-bolder font-size-h5"> Integración <br></br> Monto Total de Ventas:</strong>
                            <hr /> 
                            {salesReport?.Detail && salesReport.Detail.length > 0 ?
                            <>
                              <li> {salesReport.Detail[ 0 ].Status} - <b>{numberFormat( salesReport.Detail[ 0 ].SalesContract ?? 0 )} </b></li> 
                              <li> {salesReport.Detail[ 1 ].Status} - <b>{numberFormat( salesReport.Detail[ 1 ].SalesContract ?? 0 )} </b></li> 
                              <li> {salesReport.Detail[ 2 ].Status} - <b>{numberFormat( salesReport.Detail[ 2 ].SalesContract ?? 0 )} </b></li> 
                            </> :
                            <></>}
                </Popover>
              </div>

              <div className="row m-0">
                <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7"
                                      aria-owns="mouse-over-discountamount" 
                                      aria-haspopup="true"
                                      onMouseEnter={ handlePopoverOpenDiAm } 
                                      onMouseLeave={ handlePopoverCloseDiAm } >
                                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <span className="font-weight-bolder font-size-h3">
                    <i className="flaticon-price-tag text-warning display-4 text-white font-weight-bold"></i>{"   "}
                    {numberFormat(salesReport.DiscountAmount ?? 0)}
                  </span>
                  <span className="text-warning font-weight-bold font-size-h6 mt-2">
                    <br></br>Monto de Descuento
                  </span>
                </div>
                <Popover id="mouse-over-discountamount" 
                        className={ classes1.popover } 
                        classes={ { paper: classes1.paper } }
                        open={ openDiAm } 
                        anchorEl={ anchorDiAm } 
                        anchorOrigin={ { vertical: "top", horizontal: "center" } }
                        transformOrigin={ { vertical: "center", horizontal: "left" } } 
                        onClose={ handlePopoverCloseDiAm } 
                        disableRestoreFocus >
                          <strong className="font-weight-bolder font-size-h5"> Integración <br></br> Monto de Descuento:</strong>
                            <hr /> 
                            {salesReport?.Detail && salesReport.Detail.length > 0 ?
                            <>
                              <li> {salesReport.Detail[ 0 ].Status} - <b>{numberFormat( salesReport.Detail[ 0 ].DiscountAmount ?? 0 )} </b></li> 
                              <li> {salesReport.Detail[ 1 ].Status} - <b>{numberFormat( salesReport.Detail[ 1 ].DiscountAmount ?? 0 )} </b></li> 
                              <li> {salesReport.Detail[ 2 ].Status} - <b>{numberFormat( salesReport.Detail[ 2 ].DiscountAmount ?? 0 )} </b></li> 
                            </> :
                            <></>}
                </Popover>

              </div>
              <div className="row m-0">
                <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7"
                                                      aria-owns="mouse-over-salesamount" 
                                                      aria-haspopup="true"
                                                      onMouseEnter={ handlePopoverOpenSaAm } 
                                                      onMouseLeave={ handlePopoverCloseSaAm } >
                  <span className="font-weight-bolder font-size-h3">
                    <i className="flaticon-piggy-bank text-warning display-4 text-white font-weight-bold"></i>{"   "}
                    <span className="font-weight-bolder font-size-h3">
                        {numberFormat(salesReport.SalesAmount ?? 0)}
                    </span>
                  </span>
                  <span className="text-warning font-weight-bold font-size-h6 mt-2">
                    <br></br>Monto Neto de Ventas
                  </span>
                </div>

                <Popover id="mouse-over-salesamount" 
                        className={ classes1.popover } 
                        classes={ { paper: classes1.paper } }
                        open={ openSaAm } 
                        anchorEl={ anchorSaAm } 
                        anchorOrigin={ { vertical: "top", horizontal: "center" } }
                        transformOrigin={ { vertical: "center", horizontal: "left" } } 
                        onClose={ handlePopoverCloseSaAm } 
                        disableRestoreFocus >
                          <strong className="font-weight-bolder font-size-h5"> Integración <br></br> Neto de Ventas:</strong>
                            <hr /> 
                            {salesReport?.Detail && salesReport.Detail.length > 0 ?
                            <>
                              <li> {salesReport.Detail[ 0 ].Status} - <b>{numberFormat( salesReport.Detail[ 0 ].SalesAmount ?? 0 )} </b></li> 
                              <li> {salesReport.Detail[ 1 ].Status} - <b>{numberFormat( salesReport.Detail[ 1 ].SalesAmount ?? 0 )} </b></li> 
                              <li> {salesReport.Detail[ 2 ].Status} - <b>{numberFormat( salesReport.Detail[ 2 ].SalesAmount ?? 0 )} </b></li> 
                            </> :
                            <></>}
                </Popover>

              </div>
              <div className="row m-0">
                <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7"
                                                                      aria-owns="mouse-over-salestotal" 
                                                                      aria-haspopup="true"
                                                                      onMouseEnter={ handlePopoverOpenSaTo } 
                                                                      onMouseLeave={ handlePopoverCloseSaTo } >
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                    <i className="flaticon-diagram text-warning display-4 text-white font-weight-bold"></i>
                    <span className="font-weight-bolder font-size-h3">
                      {salesReport.SalesTotal ?? 0}
                    </span>
                  </span>
                  <span className="text-warning font-weight-bold font-size-h6 mt-2">
                    Total de ventas
                  </span>
                </div>

                <Popover id="mouse-over-salestotal" 
                        className={ classes1.popover } 
                        classes={ { paper: classes1.paper } }
                        open={ openSaTo } 
                        anchorEl={ anchorSaTo } 
                        anchorOrigin={ { vertical: "top", horizontal: "center" } }
                        transformOrigin={ { vertical: "center", horizontal: "left" } } 
                        onClose={ handlePopoverCloseSaTo } 
                        disableRestoreFocus >
                          <strong className="font-weight-bolder font-size-h5"> Integración <br></br> Total de Ventas:</strong>
                            <hr /> 
                            {salesReport?.Detail && salesReport.Detail.length > 0 ?
                            <>
                              <li> {salesReport.Detail[ 0 ].Status} - <b>{salesReport.Detail[ 0 ].SalesTotal ?? 0} </b></li> 
                              <li> {salesReport.Detail[ 1 ].Status} - <b>{salesReport.Detail[ 1 ].SalesTotal ?? 0} </b></li> 
                              <li> {salesReport.Detail[ 2 ].Status} - <b>{salesReport.Detail[ 2 ].SalesTotal ?? 0} </b></li> 
                            </> :
                            <></>}
                </Popover>

              </div>

              <div className="row m-0">
                <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7"
                                                                      aria-owns="mouse-over-downpayment" 
                                                                      aria-haspopup="true"
                                                                      onMouseEnter={ handlePopoverOpenDoPa } 
                                                                      onMouseLeave={ handlePopoverCloseDoPa } >
                  <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                    <i className="flaticon-business text-warning  display-4 text-white font-weight-bold"></i>
                    <span className="font-weight-bolder font-size-h3">
                      {numberFormat(salesReport.DownPaymentTotal ?? 0)}
                    </span>
                  </span>
                  <span className="text-warning font-weight-bold font-size-h6 mt-2">
                    Suma total de enganches
                  </span>
                </div>
              </div>

              <Popover id="mouse-over-downpayment" 
                        className={ classes1.popover } 
                        classes={ { paper: classes1.paper } }
                        open={ openDoPa } 
                        anchorEl={ anchorDoPa } 
                        anchorOrigin={ { vertical: "top", horizontal: "center" } }
                        transformOrigin={ { vertical: "center", horizontal: "left" } } 
                        onClose={ handlePopoverCloseDoPa } 
                        disableRestoreFocus >
                          <strong className="font-weight-bolder font-size-h5"> Integración <br></br> Total de Enganches:</strong>
                            <hr /> 
                            {salesReport?.Detail && salesReport.Detail.length > 0 ?
                            <>
                              <li> {salesReport.Detail[ 0 ].Status} - <b>{numberFormat( salesReport.Detail[ 0 ].DownPaymentTotal ?? 0 )} </b></li> 
                              <li> {salesReport.Detail[ 1 ].Status} - <b>{numberFormat( salesReport.Detail[ 1 ].DownPaymentTotal ?? 0 )} </b></li> 
                              <li> {salesReport.Detail[ 2 ].Status} - <b>{numberFormat( salesReport.Detail[ 2 ].DownPaymentTotal ?? 0 )} </b></li> 
                            </> :
                            <></>}
                </Popover>

            </div>
            <div className="col-md-4 col-lg-4 col-xxl-4 mb-5 bg-warning rounded-xl py-5">
              <div className="row m-0">
                <div className="col px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h3 className="card-title font-weight-bolder my-0">
                    Monto de venta cancelada
                  </h3>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <span className="font-weight-bolder font-size-h3">
                    <i className="flaticon-coins display-4 text-white font-weight-bold"></i>{" "}
                    {numberFormat(salesReport.SalesContractCancelled ?? 0)}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <div className="col px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h4 className="card-title my-0">
                    Monto de descuento de venta cancelada
                  </h4>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <span className="font-weight-bolder font-size-h3">
                    <i className="flaticon-price-tag display-4 text-white font-weight-bold"></i>{" "}
                    {numberFormat(salesReport.DiscountAmountCancelled ?? 0)}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <div className="col px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h4 className="card-title  my-0">
                    Monto neto de venta cancelada
                  </h4>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <span className="font-weight-bolder font-size-h3">
                    <i className="flaticon-piggy-bank display-4 text-white font-weight-bold"></i>{" "}
                    {numberFormat(salesReport.SalesAmountCancelled ?? 0)}
                  </span>
                </div>
              </div>
              <hr></hr>
              <div className="row m-0">
                <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                    <i className="flaticon-diagram text-warning display-4 text-white font-weight-bold"></i>
                    <span className="text-warning font-weight-bolder font-size-h3">
                      {salesReport.SalesTotalCancelled ?? 0}
                    </span>
                  </span>
                  <span className="text-warning font-weight-bold font-size-h6">
                    Total de ventas canceladas
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <div className="col bg-light-success px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                    <i className="flaticon-business text-success  display-4 text-white font-weight-bold"></i>
                    <span className="text-success font-weight-bolder font-size-h3">
                      {numberFormat(salesReport.DownPaymentTotalCancelled ?? 0)}
                    </span>
                  </span>
                  <span className="text-success font-weight-bold font-size-h6 mt-2">
                    Suma total de enganches cancelados
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
