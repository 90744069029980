import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { numberFormat } from "../../../../functions";

export function StatsSalesManagersCompare( props ) {
  var seriesCompare = [];
  var categoriesCompare = [];

  if (props.Managers?.length > 1) {
    props.Periods.map( (elem) => {
      categoriesCompare.push( `${elem.StartDate.substring( 0, 10 )} a ${elem.EndDate.substring( 0, 10 )}` );
  
    } );
  //console.log( "CATEGORIAS ----->>>>>>Z", categoriesCompare );
    props.Managers.map( (mana) => {
      let dataCompare = [];
      mana.PeriodsManager.map( (elem) => {
        dataCompare.push(
          props.Type && props.Type == 1 ? parseFloat( elem.Resume.TotalInsurance ) :
                    props.Type && props.Type == 2 ? parseFloat( elem.Resume.TotalContract ) : parseFloat( elem.Resume.Total )
        )
  
      } );
      seriesCompare.push( { name: `Gerente ${mana.ManagerName}`, data: dataCompare } );
      
    } );

  }

  const [chartData, setChartData] = useState( {
    series: seriesCompare,
    options: {
      chart: {
        type: "line",
        height: 350
      },
      stroke: {
        curve: "smooth"
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
            return numberFormat( val );
        },
      },
      xaxis: {
        categories: categoriesCompare
      },
      tooltip: {
        y: {
          formatter: (val) => numberFormat( val, 2 )
        }
      }
    }
  } );

  useEffect( () => {
    setChartData( {
      series: seriesCompare,
      options: {
        chart: {
          type: "line",
          height: 350
        },
        stroke: {
          curve: "smooth"
        },
        xaxis: {
          categories: categoriesCompare
        },
        tooltip: {
          y: {
            formatter: (val) => numberFormat( val, 2 )
          }
        }
      }
  
    } );
  }, [props] );

  return (
    <div>
      <h2>Comparación de Gerencias</h2>
      <Chart options={chartData.options} series={chartData.series} type="line" height={350} />
    </div>
  );
}
