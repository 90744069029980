import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { numberFormat } from "../../../../functions";
import { format, parseISO } from "date-fns";

export function StatsContractsDefaulters( props ) {
  var seriesDefaulters = [];
  var categoriesDefaulters = [];
  var StartDate = new Date( props.startDate._d );
  var EndDate = new Date( props.endDate._d );

  const [optionsChart, setOptionsChart] = useState( {
    options: {
      chart: {
        type: "line",
        height: 350
      },
      stroke: {
        curve: "smooth"
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
            return numberFormat( val );
        },
      },
      xaxis: {
        categories: categoriesDefaulters
      },
      tooltip: {
        y: {
          formatter: (val) => numberFormat( val, 2 )
        }
      }
    }

  } );

  const handleChartData = () => {
    if (props.data?.length > 0) {
      var dataSeries60 = [];
      var dataSeries90 = [];
      var dataSeries180 = [];
      var dataSeries180more = [];
      var dataSeriesTotal = [];
      var categoriesLoaded = [];

      props.data.map( (elem) => {
        categoriesLoaded.push( elem.SlpName );
        dataSeries60.push( elem.Days60 );
        dataSeries90.push( elem.Days90 );
        dataSeries180.push( elem.Days180 );
        dataSeries180more.push( elem.Days180More );
        dataSeriesTotal.push( elem.DefaulterTotal );

      } );
      categoriesDefaulters = categoriesLoaded;
      seriesDefaulters = [ { name: 'Hasta 60 Días', data: dataSeries60 },
                            { name: 'Hasta 90 Días', data: dataSeries90 },
                            { name: 'Hasta 180 Días', data: dataSeries180 },
                            { name: 'Más de 180 Días', data: dataSeries180more },
                            { name: 'Total', data: dataSeriesTotal } ];

    }
  };

  const [chartData, setChartData] = useState( {
    series: seriesDefaulters,
    options: optionsChart

  } );  

  useEffect( () => {
    handleChartData();
    setOptionsChart( prevState => ( { ...prevState, xaxis: { ...prevState.xaxis, categories: categoriesDefaulters } } ) );
    setChartData( { series: seriesDefaulters, options: optionsChart } );

  }, [props] );

  return (
    <div>
      <h2>Contratos Morosos</h2> 
      {`Del: ${format( StartDate, "dd/MM/yyyy" )} Al: ${format( EndDate, "dd/MM/yyyy" )}`}
      <Chart options={chartData.options} series={chartData.series} type="line" height={450} />
    </div>
  );
}