import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import CommissionsBonusesAnalyticsReport  from "../_metronic/_partials/dashboards/CommissionsBonusesAnalyticsReport";
import CommissionsReport  from "../_metronic/_partials/dashboards/CommissionsReport";
import ActivitiesDashboardPage from "../_metronic/_partials/dashboards/ActivitiesDashboard";
import SalesDashboard  from "../_metronic/_partials/dashboards/SalesDashboard";
import FinancialDashboard  from "../_metronic/_partials/dashboards/FinancialDashboard";
import SalesDashboardTeam from "../_metronic/_partials/dashboards/SalesDashboardTeam";
import MonthlyOffer from "../_metronic/_partials/dashboards/MonthlyOffer";
import SalesDashboardTotalSales from "../_metronic/_partials/dashboards/SalesDashboardTotalSales";

import { shallowEqual, useSelector } from "react-redux";

const UserModule = lazy(() => import("./modules/User"));

const ProspectModule = lazy(() => import("./modules/Prospect"));

const ActivityModule = lazy(() => import("./modules/Activity"));

const QuotationModule = lazy(() => import("./modules/Quotation"));

const ContractModule = lazy(() => import("./modules/Contract"));

const CatalogModule = lazy(() => import("./modules/Catalog"));

const ReportModule = lazy(() => import("./modules/Report"));

const CarouselModule = lazy(() => import("./modules/Carousel"));

const GuardModule = lazy(() => import("./modules/Guard"));

const RecordariModule = lazy(() => import("./modules/Recordari"));

const FuneralNetModule = lazy( () => import( "./modules/FuneralNet" ) );

export default function BasePage() {
  const { isAuthorizedCarrusel } = useSelector(
    ({ auth }) => ({
      isAuthorizedCarrusel:
        (auth.user != null && auth.user && auth.user.RoleId < 4) ||
        (auth.user != null && auth.user && (auth.user.RoleId >=12 && auth.user.RoleId <= 14)) ||
        (auth.user &&
          (auth.user.RoleId === 5 || auth.user.RoleId === 4) &&
          auth.user.TeamCarousel === 1),
    }),
    shallowEqual
  );
  const { isAuthorizedReport } = useSelector(
    ({ auth }) => ({
      isAuthorizedReport:
      ((auth.user != null && auth.user.RoleId < 8 && auth.user.RoleId !== 11) || (auth.user != null && (auth.user.RoleId === 12 || auth.user.RoleId === 13)) || (auth.user != null && auth.user.RoleId === 14) || (auth.user != null && auth.user.EmployeeId === 1831) || (auth.user != null && auth.user.EmployeeId === 4006) ),
    }),
    shallowEqual
  );

  const { isAuthorizedUser } = useSelector(
    ({ auth }) => ({
      isAuthorizedUser:
         ((auth.user != null && auth.user.RoleId < 8 && auth.user.RoleId !== 11) || (auth.user != null && auth.user.RoleId === 15)),
   
    }),
    shallowEqual
  );

  const { isAuthorizedCatalog } = useSelector(
    ({ auth }) => ({
      isAuthorizedCatalog:
         ((auth.user != null && auth.user.RoleId < 8 && auth.user.RoleId !== 11) || (auth.user != null && (auth.user.RoleId ===12 || auth.user.RoleId ===13)) ),
   
    }),
    shallowEqual
  );
  const { isAuthorizedGuard } = useSelector(
    ({ auth }) => ({
      isAuthorizedGuard:
         ((auth.user != null && auth.user.RoleId < 8 && auth.user.RoleId !== 11) || (auth.user != null && auth.user.RoleId ===13) ),
   
    }),
    shallowEqual
  );

  const { isAuthorizedContract } = useSelector(
    ({ auth }) => ({
      isAuthorizedGuard: (auth.user != null && auth.user.RoleId !== 11) || (auth.user != null && auth.user.RoleId ===13),
    }),
    shallowEqual
  );

  const { isAuthorizedRecordari } = useSelector(//quitar en caso de estar mal y checar la autorizacion
    ({ auth }) => ({
      isAuthorizedRecordari:
         ((auth.user != null && auth.user.RoleId < 8 && auth.user.RoleId !== 11) ),
   
    }),
    shallowEqual
  );

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        
          {/* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/activities-dashboard" />}
        <ContentRoute path="/activities-dashboard" component={() => <ActivitiesDashboardPage />} />
        <ContentRoute path="/dashboard" component={() => <DashboardPage />} />
        <ContentRoute path="/sales-dashboard" component={() => <SalesDashboard />} />   
        <ContentRoute path="/sales-team-dashboard" component={() => <SalesDashboardTeam />} />
        <ContentRoute path="/commissions-bonuses-analytics" component={() => <CommissionsBonusesAnalyticsReport />} />
        <ContentRoute path="/commissions-report" component={() => <CommissionsReport />} />
        <ContentRoute path="/financial-dashboard" component={() => <FinancialDashboard />} />
        <ContentRoute path="/monthly-offer" component={() => <MonthlyOffer />} />
        <ContentRoute path="/totalsales-dashboard" component={() => <SalesDashboardTotalSales />} />

        <Route path="/prospect" component={() => <ProspectModule />} />
        <Route path="/activity" component={() => <ActivityModule />} />
        <Route path="/quotation" component={() => <QuotationModule />} />
        <Route path="/contract" component={() => <ContractModule />} />

        <Route path="/funeralnet" component={ () => <FuneralNetModule />} />
        {isAuthorizedReport && (
          <Route path="/report" component={ReportModule} />
        )}
        {isAuthorizedGuard && <Route path="/guard" component={GuardModule} />}

        {isAuthorizedCatalog && (
          <Route path="/catalog" component={CatalogModule} />
        )}

        {isAuthorizedUser && (
          <Route path="/user" component={() => <UserModule />} />
        )}
        {isAuthorizedCarrusel && (
          <Route path="/carousel" component={CarouselModule} />
        )}

        {isAuthorizedRecordari && (//quitar en caso de estar mal
          <Route path="/Recordari" component={() => <RecordariModule />} />
        )}

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
