/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { numberFormat } from "../../../../functions";
import moment from "moment";

export function StatsSalesCompare( { className, compare, startDate, endDate, numSerie = 0, nManager = 0 } ) {
  let seriesT = [];
  let series1 = [];
  /* let series11 = [];
  let series12 = []; */
  let series2 = [];
  let categories = [];
//console.log( "RESUMEN ---------->>>>>>>>>>", compare.Resume );
  if (compare && compare.Periods?.length > 0) {
    compare.Periods.map( (data) => {
      seriesT.push( parseFloat( data.Resume.Total ) );
      series1.push( parseFloat( data.Resume.TotalInsurance ) );
      series2.push( parseFloat( data.Resume.TotalContract ) );
      categories.push( `${data.StartDate.substring( 0, 10 )} a ${data.EndDate.substring( 0, 10 )}` );
    } );
  }

  /* if (numSerie > 10) {  //console.log( "ES POR MANAGER ----->>>>>>>", numSerie );
    if (compare && compare.Managers?.length > 0 && compare.Managers[ nManager ].PeriodsManager.length > 0) {
      //console.log( "ENTRO A COMPARAR ------>>>>>>>", compare.Managers[ nManager ].PeriodsManager, nManager );
      compare.Managers[ nManager ].PeriodsManager.map( (data) => {
        console.log( "AGREGANDO DATA MANAGER --------->>>>>>>", data );
        series11.push( parseFloat( data.Resume.TotalInsurance ) );
        series12.push( parseFloat( data.Resume.TotalInsurance ) );

      } );
    }
  } */


  //console.log( "SERIES ----------->>>>>>>>>>>>>>", seriesT, series1, series2 );

  var titleSerie = "Comparativo TOTAL";
  var series = seriesT;
  var idGraph = "kt_stats_compare";
  var colorsGraph = ['#002060', '#F5B013'];

  if (numSerie == 1) {
    titleSerie = "Comparativo RENOVACIONES";
    series = series1;
    idGraph = "kt_stats_compareREN";
    colorsGraph = ['#008FFB', '#00E396', '#CED4DC' ]

  /* } else if (numSerie == 11) {
    titleSerie = "Gerente RENOVACIONES";
    series = series11;
    idGraph = "kt_stats_compareREN";
    colorsGraph = ['#008FFB', '#00E396', '#CED4DC' ]

  } else if (numSerie == 12) {
    titleSerie = "Gerente RENOVACIONES";
    series = series12;
    idGraph = "kt_stats_compareREN";
    colorsGraph = ['#008FFB', '#00E396', '#CED4DC' ] */

  } else if (numSerie == 2) {
    titleSerie = "Comparativo PREVISION FINAL";
    series = series2;
    idGraph = "kt_stats_comparePF";
    colorsGraph = ['#008FFB', '#00E396', '#CED4DC' ]
  
  }
//console.log( "CARGANDO GRAFICA NUM SERIE -------->>>>>>>>>>>", numSerie, series );
  useEffect( () => {
    /* const element = document.getElementById( "kt_stats_compare" ); */
    const element = document.getElementById( idGraph );
    if (!element) {
      return;

    }

    const options = {
        series: [
          {
            name: titleSerie,
            data: series,
          },
          /* {
            name: "Segundo TOTAL",
            data: seriesT,
          },
          /* {
            name: "Comparativo Renovaciones",
            data: series1,
          }, */
          /* {
            name: "Comparativo Previsión Final",
            data: series2,
          }, // */
        ],
        chart: {
          height: 350,
          type: 'area', //'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: colorsGraph, /* ['#008FFB', '#00E396', '#CED4DC' ], //['#77B6EA', '#545454'], */
        dataLabels: {
          enabled: true,
          formatter: function (val) {
              return numberFormat( val );
            },
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: categories,
          /* title: { text: 'Periodos' } */
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return numberFormat( val );
            }
          }
        
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: [ '#FDD835'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
    };
    try {
      if (compare) {
        const chart = new ApexCharts( element, options );
        chart.render();
        return function cleanUp() {
          chart.destroy();
        };
      }
    } catch {}
  }, [compare] );

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
          <div className="d-flex flex-row justify-content-between mr-2" style={{width: "100%"}}>
            <span
              className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
            >
              {/* Comparativo de Ventas */}
              {titleSerie}
            </span>
            <span className="text-dark-75 text-hover-primary">
              Del {moment( startDate ).format( "DD/MM/yyyy" )} al {moment( endDate ).format( "DD/MM/yyyy" )}
            </span>
          </div>
        </div>
        <div className="flex-grow-1">
          {compare ? (
            <div
              /* id="kt_stats_compare" */
              id={idGraph}
              className="card-rounded-bottom"
              style={{ height: "230px" }}
            >
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}